import { createAsyncThunk } from '@reduxjs/toolkit';
import { addTeamsToProject, client, createProjects, getAllProjects, getAllUsers, getProjectConfig, removeMemberFromProject, updateMemberOfaProject, updateProject, updateProjectConfig, updateProjectStatus } from '../../api/services.gen';
import { CreateProjectRequest, CreateTeamsRequest, ProjectConfigUpdateRequest, UpdateMemberRequest, UpdateProjectData, UpdateProjectRequest, UpdateProjectStatusRequest } from '../../api/types.gen';
import { setLoading, setError, setUsersByRole, setAllProjectsList, setSelectedProjectData, setProjectConfigData } from './projectSlice';
 
export const createProject = createAsyncThunk(
  'project/create',
  async (projectData: CreateProjectRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await createProjects({ body: projectData });
      if (response.data?.id) {
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to create project'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const getProjectsList = createAsyncThunk(
  'project/get',
  async(undefined, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getAllProjects();
      if (response?.data) {
        dispatch(setAllProjectsList(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get Project List'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const getUsers = createAsyncThunk(
  'user/getUsers',
  async(undefined, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      console.log("getAllUsers", client.getConfig().baseUrl);
      const response = await getAllUsers();
      if (response.data) {
        dispatch(setUsersByRole(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get the users list data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const archiveProject = createAsyncThunk(
  'project/{id}/status',
  async (statusBody: UpdateProjectStatusRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateProjectStatus({ path:{ id: statusBody?.id }, body: statusBody });
      if (response.data?.id) {
        dispatch(setError(null));
        dispatch(setSelectedProjectData(response.data));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update project as archive'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const EditProjectData = createAsyncThunk(
  'project/{id}',
  async (projectData: UpdateProjectRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateProject({ path:{ id: projectData?.id }, body: projectData });
      if (response.data?.id) {
        dispatch(setError(null));
        dispatch(setSelectedProjectData(response.data));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update project data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getProjectConfigData = createAsyncThunk(
  'project/{id}/config',
  async(id:string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getProjectConfig({ path:{ id: id }});
      if (response?.data) {
        dispatch(setProjectConfigData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get Project Config details'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const editProjectConfigDetails = createAsyncThunk(
  'project/{id}/config',
  async(projectConfigData: ProjectConfigUpdateRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateProjectConfig({ path:{ id: projectConfigData?.projectId }, body:projectConfigData });
      if (response?.data) {
        dispatch(setProjectConfigData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update Project Config details'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const removeProjectMember = createAsyncThunk(
  '/project/{id}/member/{userEmail}',
  async(userEmailData: {projectId: string, userEmail: string}, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await removeMemberFromProject({path: {id: userEmailData?.projectId, userEmail: userEmailData?.userEmail}});
      if (response?.data) {
        dispatch(setSelectedProjectData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to delete member from Project'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const addProjectMember = createAsyncThunk(
  '/project/{id}/teams',
  async(teamsData: CreateTeamsRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await addTeamsToProject({path: {id: teamsData?.id}, body:teamsData});
      if (response?.data) {
        dispatch(setSelectedProjectData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to add member to Project'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const editProjectMemberRole = createAsyncThunk(
  '/project/{id}/member/{userEmail}',
  async(memberUpdateData: UpdateMemberRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateMemberOfaProject({path: {id: memberUpdateData?.id, userEmail: memberUpdateData?.userEmail}, body:memberUpdateData});
      if (response?.data) {
        dispatch(setSelectedProjectData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update member role in Project'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);