
import React from 'react';
import { LoginForm } from '../../components/login';
import { LoginFormProvider } from '../../components/login';
import { KeycloakService } from '../../services/KeycloakService';

const LoginContainer: React.FC = () => {
    const onSubmitForm  = () => {
        KeycloakService.keycloak.login({
            redirectUri: `${window.location.origin}/dashboard`
        }).catch(err => {
            console.error(err);
        });
    }; 

    return (
        <LoginFormProvider onSubmit={onSubmitForm}>
            <LoginForm/>
        </LoginFormProvider>
    )
}

export default LoginContainer;