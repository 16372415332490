import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

type LoginFormValues = {
  email: string;
  password: string;
};

type LoginFormProviderProps = {
  onSubmit: SubmitHandler<LoginFormValues>;
  children: React.ReactNode;
};

const LoginFormProvider: React.FC<LoginFormProviderProps> = ({ onSubmit, children }) => {
  const methods = useForm<LoginFormValues>();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default LoginFormProvider;
