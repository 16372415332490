import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

type AddUserFormValues = {
    emailId: string;
    role: string;
};

type AddUserFormProviderProps = {
  onSubmit: SubmitHandler<AddUserFormValues>;
  children: React.ReactNode;
};

const AddUserFormProvider: React.FC<AddUserFormProviderProps> = ({ onSubmit, children }) => {
  const methods = useForm<AddUserFormValues>();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default AddUserFormProvider;
