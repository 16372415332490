import { Panel } from "@appkit4/react-components";
import ControlledMultiSelect from "../../../core/components/ControlledMultiselect";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectUsersListByRole } from "../../../store/project/projectSelectors";
import { KeycloakService } from "../../../services/KeycloakService";
import { useState } from "react";
import { UserDTO } from "../../../api";

type ProjectFormErrors = {
    name: string;
    type: string;
    startDate: string;
    endDate: string;
    description: string;
    adminEmails: string;
    managerEmails: string;
};

const AddUserBody: React.FC<{addAdminEmails: Function, addManagerEmails: Function}> = ({addAdminEmails, addManagerEmails}) => {
    const [adminEmailsList, setAdminEmailsList] = useState([]);
    const [managerEmailsList, setManagerEmailsList] = useState([]);
    const { formState: { errors } } = useFormContext<ProjectFormErrors>();
    const listOfUsers = useSelector(selectUsersListByRole);
    const adminUsers:{ value: string, label: string }[]  = listOfUsers.filter((usersList: any) => usersList.role === "admin")[0]?.users.filter((user: any) => user.email !== KeycloakService.getEmail()).map((adminUser:UserDTO) => {return{value: adminUser.email, label: adminUser.email}});
    const managersList: Set<string> = new Set(managerEmailsList);
    const updatedAdminUsers = adminUsers.filter((obj: { value: string, label: string } ) => !managersList.has(obj.value));
    const managerUsers = listOfUsers?.filter((usersList: any) => usersList.role === "migration-manager")[0]?.users.map((user:any) => {return{value: user.email, label: user.email}});
    const adminsList: Set<string> = new Set(adminEmailsList);
    const updatedManagerUsers = managerUsers?.filter((obj: { value: string, label: string } ) => !adminsList.has(obj.value));

    const addAdminEmailsInBody = (emailsList:any) => {
        setAdminEmailsList(emailsList);
        addAdminEmails(emailsList);
    }
    const addManagerEmailsInBody = (emailsList:any) => {
        setManagerEmailsList(emailsList);
        addManagerEmails(emailsList);
    }
    return (
        <>
            <Panel className="border-b" title="Invite users into the Admin role" >
                <p>The Admin role is responsible for managing users, creating and overseeing migration projects, and ensuring that business and technology interests are aligned with all stakeholders.</p>
                <ControlledMultiSelect name="adminEmails" required={false} label="Admins" error={errors.adminEmails} data={updatedAdminUsers} addEmails={addAdminEmailsInBody}/>
                {errors.adminEmails && <p className="text-red-500 mt-2">{errors.adminEmails.message}</p>}
            </Panel>
            <Panel bordered={false} title="Invite users into the Manager role" >
                <p>The Migration Manager, is responsible for overseeing and ensuring the successful migration of data assets.This role involves defining migration waves, coordinating with business units, and validating the migration process.</p>
                <ControlledMultiSelect name="managerEmails" required={false} label="Managers" error={errors.managerEmails} data={updatedManagerUsers} addEmails={addManagerEmailsInBody}/>
                {errors.managerEmails && <p className="text-red-500 mt-2">{errors.managerEmails.message}</p>}
            </Panel>
        </>
    )
}

export default AddUserBody;