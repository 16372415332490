import { Button, Modal } from "@appkit4/react-components";
import AddUserBody from "./AddUserBody";
import { useState } from "react";

interface addUserModalProps {
    visible: boolean;
    closeModal: Function;
    addUsers: Function;
}

const AddUserModal: React.FC<addUserModalProps> = ({ visible, closeModal, addUsers }) => {
    const [adminEmails, setAdminEmails] = useState([]);
    const [managerEmails, setManagerEmails] = useState([]);
    const addAdminEmails = (emailsList:any) => {
        setAdminEmails(emailsList);
    }
    const addManagerEmails = (emailsList:any) => {
        setManagerEmails(emailsList);
    }
    return (
        <Modal
            visible={visible}
            title={"Define team"}
            onCancel={closeModal}
            modalStyle={{ width: '33.75rem' }}
            footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '64px' }}
            footer={
                <>
                    <Button onClick={closeModal} kind="secondary">Cancel</Button>
                    <Button onClick={addUsers}>Add {adminEmails?.length + managerEmails?.length} users</Button>
                </>
            }
            bodyStyle={{ minHeight: '92px' }}
        >
            <AddUserBody addAdminEmails={addAdminEmails} addManagerEmails={addManagerEmails}/>
        </Modal>);
}

export default AddUserModal;