import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectConfigDTO, ProjectDTO, UserByRole } from '../../api/types.gen';

interface ProjectState {
  loading: boolean;
  error: string | null;
  userEmailsTableData: Array<any>;
  projectInitialDetails: object;
  usersByRoleList: UserByRole[];
  allProjectsList: ProjectDTO[];
  userEmailsForProject: Array<any>;
  selectedProjectData: ProjectDTO;
  selectedProjectConfigData: ProjectConfigDTO;
}

const initialState: ProjectState = {
  loading: false,
  error: null,
  allProjectsList: [],
  userEmailsTableData: [],
  usersByRoleList: [],
  projectInitialDetails: {
    name: "",
    type: "",
    startDate: null,
    endDate: null,
    description: "",
    adminEmails: [],
    managerEmails: []
  },
  selectedProjectData: {
    name: "",
    id: "",
    projectStatus: "",
    type: "",
    startDate: "",
    endDate: "",
    description: "",
    adminList: [],
    managerList: []
  },
  userEmailsForProject: [],
  selectedProjectConfigData: {
    projectId: "",
    config: {
    }
  }
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setUserEmailsTableData: (state, action: PayloadAction<Array<Object>>) => {
      state.userEmailsTableData = action.payload
    },
    setProjectInitialDetails: (state, action: PayloadAction<object>) => {
      state.projectInitialDetails = action.payload
    },
    setUsersByRole: (state, action: PayloadAction<UserByRole[]>) => {
      state.usersByRoleList = action.payload
    },
    setAllProjectsList: (state, action: PayloadAction<ProjectDTO[]>) => {
      state.allProjectsList = action.payload
    },
    setUserEmailsForProject: (state, action: PayloadAction<Array<Object>>) => {
      state.userEmailsForProject = action.payload
    },
    setSelectedProjectData: (state, action: PayloadAction<ProjectDTO>) => {
      state.selectedProjectData = action.payload
    },
    setProjectConfigData: (state, action: PayloadAction<ProjectConfigDTO>) => {
      state.selectedProjectConfigData = action.payload
    }
  },
});

export const { setLoading, setError, setUserEmailsTableData, setProjectInitialDetails, setUsersByRole, setAllProjectsList, setUserEmailsForProject, setSelectedProjectData, setProjectConfigData } = projectSlice.actions;
export default projectSlice.reducer;