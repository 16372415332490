import { useSelector } from "react-redux";
import { selectInitialProjectDetails } from "../../store/project/projectSelectors";
import UsersTable from "./projectDetails/UsersTable";
import { Button, CalendarPicker, Input, Panel, Select, TextArea } from "@appkit4/react-components";
import { dataAssetstobeMigrated, typeData } from "./mockData";
import { CreateProjectRequest } from "../../api";

interface ProjectFormProps {
    onSubmit: (project: CreateProjectRequest) => void;
    loading: boolean;
    setActiveIndex: Function
  }
  
  const ReviewProjectData: React.FC<ProjectFormProps> = ({ onSubmit, loading, setActiveIndex }) => {
    const initialProjectDetails: any = useSelector(selectInitialProjectDetails);
    const submitProjectData = () => {
        const payload: CreateProjectRequest = {
            name: initialProjectDetails.name,
            type: initialProjectDetails.type,
            description: initialProjectDetails.description,
            adminList: initialProjectDetails.adminEmails,
            managerList: initialProjectDetails.managerEmails,
            startDate: initialProjectDetails.startDate,
            endDate: initialProjectDetails.endDate,
        }
        onSubmit(payload);
    }
    return (
        <div className='flex-1 bg-gray-50 mt-4'>
            <h1 className="font-sans font-bold font-medium text-2xl py-4">Review</h1>
            <div className="px-4 py-4 bg-white">
                <div className="px-4 py-4 bg-white">
                    <div className="mb-4">
                        <div className="flex justify-between mr-8">
                            <h3 className="font-medium text-xl py-4">Project Details</h3>
                            <Button className="mt-2" kind='secondary' icon="icon-edit-outline" onClick={() => setActiveIndex(0)}>Edit</Button>
                        </div>
                        <div className="w-2/3">
                            <Input name="name" required={true} type="name" title="Name" disabled value={initialProjectDetails?.name} />
                        </div>
                        <div className="w-2/3 flex mb-4 mt-4">
                            <div className="w-full">
                                <Select disabled value={initialProjectDetails?.type} placeholder="Type" data={typeData} />
                            </div>
                            <div className="ml-2">
                                <CalendarPicker disabled name="startDate" required={true} fieldTitle="Start Date" value={new Date(initialProjectDetails?.startDate)} />
                            </div>
                            <div className="ml-2">
                                <CalendarPicker disabled name="endDate" required={true} fieldTitle="End Date" value={new Date(initialProjectDetails?.endDate)} />
                            </div>
                        </div>
                        <div className="w-2/3">
                            <TextArea disabled name="description" required={true} title="Description" maxLength={420} value={initialProjectDetails?.description} />
                        </div>
                    </div>
                    <div className="mb-4">
                        <h3 className="font-medium text-xl pt-4">Data assets to be migrated(64,259)</h3>
                        <div className="flex flex-wrap justify-between">
                        {dataAssetstobeMigrated.map((dataAsset, index)=> 
                            <div className="w-1/5 mt-4 px-2" key={index}>
                                <Panel className="bg-gray-100">
                                    <div className="font-medium text-xl py-2">{dataAsset.name}</div>
                                    <span className={`Appkit4-icon icon-${dataAsset.icon}-outline ap-font-24`} aria-hidden="true"></span>
                                    <span className="ml-2 font-bold text-4xl">{dataAsset.noOfAssets}</span>
                                </Panel>
                            </div>
                        )}
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="flex justify-between mr-8">
                            <h3 className="font-medium text-xl py-4">Users</h3>
                            <Button className="mt-2" kind='secondary' icon="icon-edit-outline" onClick={() => setActiveIndex(0)}>Edit</Button>
                        </div>
                        <UsersTable />
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button kind='primary' loading={loading} icon="icon-check-hook-outline" onClick={submitProjectData}>Complete</Button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ReviewProjectData;