export const navigationItemsWithProject = [
    {
        name: 'Home',
        prefixIcon: 'grid-view',
        prefixCollapsedIcon: 'grid-view',
    },
    {
        name: 'Project Overview',
        prefixIcon: 'list-view',
        prefixCollapsedIcon: 'list-view',
    },
    {
        name: 'Team Members',
        prefixIcon: 'community',
        prefixCollapsedIcon: 'community',
    },
    {
        name: 'Lineage Model',
        prefixIcon: 'upload',
        prefixCollapsedIcon: 'upload',
    },
    {
        name: 'Data Assets',
        prefixIcon: 'data',
        prefixCollapsedIcon: 'data',
    },
    {
        name: 'Project Configuration',
        prefixIcon: 'cogs',
        prefixCollapsedIcon: 'cogs',
    }
];

export const navigationItemsWithoutProject = [
    {
        name: 'Home',
        prefixIcon: 'grid-view',
        prefixCollapsedIcon: 'grid-view',
    }
];

export const initialProjectDTO =
{
    "name": "",
    "type": "",
    "description": "",
    "startDate": "",
    "endDate": "",
    "id": "",
    "projectStatus": ""
}

export const projectRoles = [
    {
        value: "migration-manager",
        label: "Manager"
    },
    {
        value: "business-user",
        label: "Business User"
    }
]