import React from 'react';
import Logo from "../../assets/images/logo.png";

const Header: React.FC = () => {
  return (
    <header className="bg-white text-white container mx-auto flex ml-8 mt-2">
      <img src={Logo} alt="Logo" height="89px" width="117px" />
    </header>
  );
};

export default Header;
