import { Breadcrumb, BreadcrumbItem, Button, Loading, Panel } from "@appkit4/react-components";
import { ProjectDTO } from "../../api";
import { useEffect, useState } from "react";
import ArchiveProjectConfModal from "../../components/project/projectDetails/ArchiveProjectConfModal";
import EditProjectDetailsModal from "../../components/project/projectDetails/EditProjectDetailsModal";
import EditProjectConfigModal from "../../components/project/projectDetails/EditProjectConfigModal";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { archiveProject, getProjectConfigData, getProjectsList } from "../../store/project/projectThunks";
import { selectProjectConfigData, selectProjectError, selectProjectLoading } from "../../store/project/projectSelectors";
import { useSelector } from "react-redux";

const ProjectConfigContainer: React.FC<({ selectedProject: ProjectDTO, setSelectedKey: (key: string) => void })> = ({ selectedProject, setSelectedKey }) => {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const projectConfigData = useSelector(selectProjectConfigData);
    const [isEditModalVisible, setIsVisibleEditModal] = useState(false);
    const [isConfigModalVisible, setIsConfigModalVisible] = useState(false);
    useEffect(() => {
        dispatch(getProjectConfigData(selectedProject?.id))
    },[dispatch, selectedProject?.id]);
    const closeConfigModal = () => {
        setIsConfigModalVisible(false);
    }
    const closeEditModal = () => {
        setIsVisibleEditModal(false);
    }
    const closeModal = () => {
        setVisible(false);
    }
    const archiveProjectMethod = () => {
        dispatch(archiveProject({id: selectedProject?.id, status: "archive"}));
        setVisible(false);
    }
    const loading = useSelector(selectProjectLoading);
    const error = useSelector(selectProjectError);
    if(loading){
        return(
            <Loading loadingType='circular' indeterminate={true} compact={false}></Loading>
        )
    }
    return(
        <div className="m-8">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => {
                        dispatch(getProjectsList()); 
                        setSelectedKey('0')
                    }}>All Projects</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => setSelectedKey('1')}>{selectedProject?.name}</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    Project Configuration
                </BreadcrumbItem>
            </Breadcrumb>
            <div>
                <h1 className="font-sans font-medium text-xl mt-4">Project Configuration</h1>
            </div>
            <Panel title={"Edit project details"} className="mt-4">
                <p>Here you can edit the project, change the title, date and description.</p>
                <div className="flex justify-end">
                    <Button kind='secondary' icon="icon-edit-outline" onClick={() => setIsVisibleEditModal(true)}>Edit details</Button>
                </div>
                <EditProjectDetailsModal visible={isEditModalVisible} closeModal={closeEditModal} selectedProject={selectedProject} />
            </Panel>
            <Panel title={"Archive Project"} className="mt-4">
                <p>Archiving this project will have significant impacts; it will be moved to a separate section, making it less accessible; and all collaborators will lose their editing privileges and will be limited to view-only access. Please confirm that you understand these implications and that you want to proceed with archiving the project. This action cannot be undone.</p>
                <div className="flex justify-end mt-4">
                    <Button kind='secondary' disabled={selectedProject?.projectStatus === "archive"} icon="icon-archive-outline" onClick={() => setVisible(true)}>Archive Projet</Button>
                </div>
                <ArchiveProjectConfModal visible={visible} closeModal={closeModal} archiveProject={archiveProjectMethod} />
            </Panel>
            <Panel title={"Configurations"} className="mt-4">
                <div className="mt-2">
                    <p>GCS Bucket</p>
                    <p className="font-bold text-black">xxxxx</p>
                </div>
                <div className="mt-2">
                    <p>Folder</p>
                    <p className="font-bold text-black">xxxxx</p>
                </div>
                <div className="mt-2">
                    <p>Service Account</p>
                    <p className="font-bold text-black">xxxxx</p>
                </div>
                <div className="flex justify-end">
                    <Button kind='secondary' icon="icon-edit-outline" onClick={() => setIsConfigModalVisible(true)}>Edit details</Button>
                </div>
                <EditProjectConfigModal visible={isConfigModalVisible} closeModal={closeConfigModal} configData = {projectConfigData}/>
            </Panel>
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    )
}

export default ProjectConfigContainer;