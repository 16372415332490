import { Button, Modal } from "@appkit4/react-components";

interface DeleteUserConfModalProps {
    visible: boolean;
    closeModal: Function;
    deleteUser: Function;
}

const DeleteUserConfModal: React.FC<DeleteUserConfModalProps> = ({ visible, closeModal, deleteUser }) => {
    return (
        <Modal
            visible={visible}
            title={"Delete user?"}
            onCancel={closeModal}
            modalStyle={{ width: '33.75rem' }}
            footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '64px' }}
            footer={
                <>
                    <Button onClick={closeModal} kind="secondary">Cancel</Button>
                    <Button kind="negative" onClick={deleteUser}>Delete</Button>
                </>
            }
            bodyStyle={{ minHeight: '92px' }}
        >
            <p>Are you sure you want to delete this user? This action is permanent.</p>
        </Modal>);
}

export default DeleteUserConfModal;