import React, { useEffect, useState } from 'react';
import { LandingContainer } from '../../containers/landing';
import { KeycloakService } from '../../services/KeycloakService';
import { LoggedInFooter, LoggedInHeader } from '../../core/components';
import { Navigation, NavigationItem } from '@appkit4/react-components/navigation';
import { DataAssetsContainer, LineageModelContainer, ProjectConfigContainer, ProjectDetailsContainer, TeamMembersContainer } from '../../containers/project';
import { getProjectsList, getUsers } from '../../store/project/projectThunks';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectAllProjectsList, selectSelectedProjectData } from '../../store/project/projectSelectors';
import { navigationItemsWithProject, navigationItemsWithoutProject } from '../../services/constants';
import { ProjectDTO } from '../../api';
import { setSelectedProjectData } from '../../store/project/projectSlice';

const LandingPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [collapsed, setCollapsed] = useState(true);
    const [selectedKey, setSelectedKey] = useState('0');
    const selectedProject = useSelector(selectSelectedProjectData);
    const onCollapseEvent = (collapsed: boolean, event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
        setCollapsed(collapsed);
    }
    const listOfProjects = useSelector(selectAllProjectsList);
    useEffect(() => {
        dispatch(getProjectsList());
        dispatch(getUsers());
    },[dispatch]);
    const goToViewProject = (project: ProjectDTO) => {
        dispatch(setSelectedProjectData(project));
        setSelectedKey('1');
    }
    const navList: NavigationItem[] = listOfProjects?.length > 0 ? navigationItemsWithProject : navigationItemsWithoutProject;
    const renderContent = (selectedKey: string) => {
        switch (selectedKey) {
            case '0':
                return <LandingContainer goToViewProject={goToViewProject}/>;
            case '1':
                return <ProjectDetailsContainer selectedProject={selectedProject} setSelectedKey={setSelectedKey}/>;
            case '2':
                return <TeamMembersContainer selectedProject={selectedProject} setSelectedKey={setSelectedKey}/>;
            case '3':
                return <LineageModelContainer />;
            case '4':
                return <DataAssetsContainer />;
            case '5':
                return <ProjectConfigContainer selectedProject={selectedProject} setSelectedKey={setSelectedKey}/>;
            default:
                return <div>Unknown status</div>;
        }
    };
    return (
        <div className="h-screen">
            <LoggedInHeader />
            <div className="flex">
                {KeycloakService.isAuthorizedRole("admin") ?
                    <>
                        <Navigation
                            collapsed={collapsed}
                            width={280}
                            className="sitedemo"
                            navList={navList}
                            hasLogo={false}
                            selectedKey={selectedKey}
                            style={{ height: '90vh' }}
                            onClickCollapseEvent={onCollapseEvent}>
                        </Navigation>
                        <div className="flex flex-col w-full">
                            <div className='flex-1 bg-gray-50'>
                                {renderContent(selectedKey)}
                            </div>
                            <LoggedInFooter />
                        </div>
                    </>
                    :
                    <div className="flex flex-col h-screen w-full">
                        <div className='flex-1 bg-gray-50'>
                            <div>You are not an admin</div>
                        </div>
                        <LoggedInFooter />
                    </div>}
            </div>
        </div>
    );
};

export default LandingPage;