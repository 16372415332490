import { CalendarPicker } from '@appkit4/react-components';
import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import classNames from 'classnames';

type ControlledDatePickerProps = {
  name: string;
  label?: string;
  error?: FieldError,
  required?: boolean,
  onChange?: Function,
  maxDate?: Date,
  minDate?: Date
};

const ControlledDatePicker: React.FC<ControlledDatePickerProps> = ({ name, label = "", error, required = false, onChange={}, maxDate, minDate}) => {
  const { control } = useFormContext();
  return (
    <div>
      <Controller
        name={name}
        control={control}
        rules={{ required: `Missing ${label.toLowerCase()}` }}
        render={({ field }) => <CalendarPicker
        className={classNames({
            'error': error
        })}
        placeholder="mm/dd/yyyy"
        locale="en"
        fieldTitle={label}
        required={required}
        onValueChange={onChange}
        maxDate={maxDate}
        minDate={minDate}
        id={name} {...field} />}
      /> 
    </div>
  );
};

export default ControlledDatePicker;
