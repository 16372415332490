
import React from 'react';
import { Footer } from '@appkit4/react-components/footer';

const FooterApp: React.FC = () => {
    const footerContent = `© 2024 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <a class='ap-link' href='https://www.pwc.com/structure' 
target='_blank'>www.pwc.com/structure</a> for further details.`;
    const footerType = 'text';

    return (
        <div className="mx-10 my-4">
            <Footer className="text-xs" content={footerContent} type={footerType}></Footer>
        </div>
    );

};

export default FooterApp;
