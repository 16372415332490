import { Footer } from "@appkit4/react-components";

const LoggedInFooter = () => {
    const footerContent = `© 2024 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity.<br/> Please see <a class='ap-link' href='https://www.pwc.com/structure' 
            target='_blank'>www.pwc.com/structure</a> for further details.`;
    const footerType = 'text';
    return(
        <Footer className="p-4" content={footerContent} type={footerType}></Footer>
    )
}

export default LoggedInFooter;