import React, { useEffect, useState, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { KeycloakService } from "../../services/KeycloakService";

function NeedsSignIn() {
  const { pathname } = useLocation();
  return <Navigate state={{ redirectAfterSignIn: pathname }} to={"/"} />;
}

const UserDataLoader:React.FC<{children: ReactNode}> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    Promise.all([]).then(() => {
      setIsLoaded(true);
    });
  }, []);

  return isLoaded ? <>{children}</> : null;
};

const NeedsAuth:React.FC<{children: ReactNode}> = ({ children }) => {

  if (KeycloakService.isAuthorized()) {
    return <UserDataLoader>{children}</UserDataLoader>;
  } else {
    return <NeedsSignIn />;
  }
};

export default NeedsAuth;
