import { Button, Panel } from "@appkit4/react-components";


const LineageModelDetails: React.FC = () => {
  return (
    <div className="pt-4">
      <Panel bordered={false} title="Pull available lineage model" >
        <p>During the migration, you will have the ability to access additional lineage modes or add any pending ones. </p>
        <p>Once these lineage models are available, you will receive a notification.</p>
        <Button className="my-6" kind='primary' icon="icon-upload-outline" type="submit">Pull lineage model</Button>
      </Panel>

    </div>
  )
}

export default LineageModelDetails;