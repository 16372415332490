import { Button } from '@appkit4/react-components';
import React from 'react';
import { Checkbox } from '@appkit4/react-components/checkbox';
import { ControlledInput } from '../../core/components';
import { useFormContext } from 'react-hook-form';

type LoginFormErrors = {
    email: string;
    password: string
};

const LoginForm: React.FC = () => {
    const isLoginFieldsOpen = false;
    const { formState: { errors } } = useFormContext<LoginFormErrors>();
    return (
        <section className="w-80 ml-20">
            <h6>Welcome to</h6>
            <h1 className="text-4xl font-semibold mb-4">Data Migration Workbench</h1>
            {isLoginFieldsOpen
                ?
                <>
                    <div className="my-4">
                        <ControlledInput name="email" type="email" label="Email address" error={errors.email} />
                        {errors.email && <p className="text-red-500 mt-2">{errors.email.message}</p>}
                    </div>
                    <div className="mt-4 mb-2">
                        <ControlledInput name="password" type="password" label="Password" error={errors.password} />
                        {errors.password && <p className="text-red-500 mt-2">{errors.password.message}</p>}
                    </div>
                    <div className="flex mt-0 mb-4">
                        <div className="flex-1">
                            <Checkbox defaultChecked>Remember me</Checkbox>
                        </div>
                        <div className="flex-1 text-right">
                            <a href="www.google.com" className="text-blue-900 hover:text-blue-800 underline focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                                Forgot password?
                            </a>
                        </div>
                    </div>
                    <Button className="w-full" kind='primary' type="submit">Login</Button>
                </>
                :
                <Button className="w-full" kind='primary' type="submit">Login</Button>
            }
            <div className='mt-4'>
                <a href="www.google.com" className="text-blue-900 hover:text-blue-800 underline focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                    Need Support?
                </a>
            </div>
        </section>
    );
};

export default LoginForm;
