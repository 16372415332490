import { Button, Loading, Panel } from '@appkit4/react-components';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import { ProjectDTO } from '../../api';
import { dataAssetstobeMigrated } from '../../components/project/mockData';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import { getProjectsList } from '../../store/project/projectThunks';
import { selectProjectError, selectProjectLoading } from '../../store/project/projectSelectors';
import { useSelector } from 'react-redux';
const ProjectDetailsContainer: React.FC<{ selectedProject: ProjectDTO, setSelectedKey: (key: string) => void }> = ({ selectedProject, setSelectedKey }) => {
    const dispatch = useDispatch<AppDispatch>();
    const loading = useSelector(selectProjectLoading);
    const error = useSelector(selectProjectError);
    if(loading){
        return(
            <Loading loadingType='circular' indeterminate={true} compact={false}></Loading>
        )
    }
    return (
        <div className="m-8">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => {
                        dispatch(getProjectsList()); 
                        setSelectedKey('0')
                    }}>All Projects</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    {selectedProject?.name}
                </BreadcrumbItem>
            </Breadcrumb>
            <h1 className="font-sans font-medium text-xl mt-4">{selectedProject?.name}</h1>
            <div className="text-[#696969]">{selectedProject?.description}</div>
            <div className="flex mt-4">
                <Panel title={"Team Members"} className="flex-1 mr-2">
                    <p>This project has {(selectedProject?.adminList?.length || 0) + (selectedProject?.managerList?.length || 0)} users</p>
                    <div className="flex justify-end mt-8">
                        <Button kind='secondary' icon="icon-community-outline" onClick={() => setSelectedKey('2')}>Manage members</Button>
                    </div>
                </Panel>
                <Panel title={"Project Configuration"} className="flex-1 ml-2">
                    <p>Edit the project and other options</p>
                    <div className="flex justify-end mt-8">
                        <Button kind='secondary' icon="icon-cogs-outline" onClick={() => setSelectedKey('5')}>Configuration</Button>
                    </div>
                </Panel>
            </div>
            <div className="flex mt-4">
                <Panel className="flex-1">
                    <div className="flex justify-between">
                        <div className="mt-4">
                            <h1 className="font-medium text-xl py-2">Project Assets</h1>
                            <p>6230 data assets available</p>
                        </div>
                        <div className="flex justify-end mt-6 mr-2">
                            <Button kind='secondary' icon="icon-arrow-right-outline" onClick={() => setSelectedKey('4')}>See all</Button>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-between">
                        {dataAssetstobeMigrated.map((dataAsset, index) =>
                            <div className="w-1/5 mt-4 px-2" key={index}>
                                <Panel className="bg-gray-100">
                                    <div className="font-medium text-xl py-2">{dataAsset.name}</div>
                                    <span className={`Appkit4-icon icon-${dataAsset.icon}-outline ap-font-24`} aria-hidden="true"></span>
                                    <span className="ml-2 font-bold text-4xl">{dataAsset.noOfAssets}</span>
                                </Panel>
                            </div>
                        )}
                    </div>
                </Panel>
            </div>
            <div className="flex mt-4">
                <Panel title={"Current Status"} className="flex-1">
                    <p>Summary</p>
                </Panel>
            </div>
            <div className="flex mt-4">
                <Panel title={"View lineage model"} className="flex-1">
                    <p>During the migration, you will have the ability to access additional lineage modes or add any pending ones. Once these lineage models are available, you will receive a notification.</p>
                    <div className="flex justify-end mt-8">
                        <Button kind='secondary' icon="icon-upload-outline" onClick={() => setSelectedKey('3')}>Pull new lineage</Button>
                    </div>
                </Panel>
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default ProjectDetailsContainer;