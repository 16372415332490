import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

type ProjectFormValues = {
  name: string;
  type: string;
  startDate: Date;
  endDate: Date;
  description: string;
  adminEmails: Array<string>;
  managerEmails: Array<string>
};

type ProjectFormProviderProps = {
  onSubmit: SubmitHandler<ProjectFormValues>;
  children: React.ReactNode;
};

const ProjectFormProvider: React.FC<ProjectFormProviderProps> = ({ onSubmit, children }) => {
  const methods = useForm<ProjectFormValues>();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default ProjectFormProvider;
