import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

type ProjectConfigFormValues = {
    gcsBucket: string;
    folder: string;
    serviceAccount: string;
};

type ProjectConfigFormProviderProps = {
  onSubmit: SubmitHandler<ProjectConfigFormValues>;
  children: React.ReactNode;
};

const ProjectConfigFormProvider: React.FC<ProjectConfigFormProviderProps> = ({ onSubmit, children }) => {
  const methods = useForm<ProjectConfigFormValues>();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default ProjectConfigFormProvider;
