import { Button } from "@appkit4/react-components";
import backGroundImage from "../../assets/images/SuccessProject.svg";
import { useNavigate } from "react-router-dom";
const SuccessProjectCreate: React.FC = () => {
    const navigate = useNavigate();
    const backgroundImageStyle = {
        backgroundImage: `url(${backGroundImage})`,
        backgroundRepeat: 'no-repeat',
        height: '13rem'
    };

    const gotoDashboard = () => {
        navigate("/dashboard");
    }

    return(
        <div className='flex-1 bg-gray-50 mt-4'>
            <h1 className="font-sans font-bold font-medium text-2xl py-4">New migration project</h1>
            <div className="px-4 py-4 bg-white">
                <div className="px-4 py-4 bg-white">
                    <div className="mb-4">
                        <div style={backgroundImageStyle} className="bg-center"></div>
                        <div className="flex flex justify-center text-center items-center">
                            <span className="text-green-500 mx-2 Appkit4-icon icon-circle-checkmark-fill ap-font-24" aria-hidden="true"></span>
                            <span>The new migration project is successfully created.</span>
                        </div>
                        <div className="flex justify-end">
                            <Button kind='primary' icon="icon-arrow-right-outline" onClick={gotoDashboard}>Go to Dashboard</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuccessProjectCreate;