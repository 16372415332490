export const getOrgName = (email:string) => {
    const domainRegex = /^[^\s@]+@([^\s@]+\.[^\s@]+)$/;
    const match = email.match(domainRegex);
    if (match) {
        const domain = match[1];
        const domainParts = domain.split('.');
        return domainParts[0];
    }
    return null
}

export const removeDuplicates = (arr:any, key:any) => {
    const seen = new Set();
    return arr.filter((item:any) => {
      const keyValue = item[key];
      if (seen.has(keyValue)) {
        return false;
      } else {
        seen.add(keyValue);
        return true;
      }
    });
  }

export const uniqueArray = (a: string[]) => {
    return a.sort().filter(function(item: any, pos: any, ary: any) {
        return !pos || item !== ary[pos - 1];
    });
}