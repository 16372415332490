import { Header } from '@appkit4/react-components/header';
import { Avatar } from '@appkit4/react-components/avatar';
import { DropdownButton } from '@appkit4/react-components/dropdown';
import { KeycloakService } from '../../services/KeycloakService';
import { useNavigate } from 'react-router-dom';

const LoggedInHeader = () => {
    const initials = KeycloakService.getInitials();
    const isAdminText = KeycloakService.getRoles().includes("admin") ? "Admin" : "";
    const isMigrationManagerText = KeycloakService.getRoles().includes("migration-manager") ? "Migration Manager" : "";
    const isBusinessUserText = KeycloakService.getRoles().includes("business-user") ? "Business User" : "";
    const navigate = useNavigate();
    const onClickLogo = () => {
        navigate("/dashboard");
    }
    const menuItemClicked = (e: any) => {
        if (e === "logout")
            KeycloakService.logout();
    }
    return (
        <Header
            type="transparent"
            compact={false}
            onClickLogo={onClickLogo}
            titleTemplate={() => `Migration Workbench | ${isAdminText || isMigrationManagerText || isBusinessUserText}`}
            userTemplate={() => (
                <DropdownButton
                    customTriggerNode={true}
                    customTriggerClassName="custom-node"
                    onSelect={menuItemClicked}
                    data={[
                        { value: 'logout', label: 'Logout', iconName: 'lockclosed-locked-outline' }
                    ]}
                    prefixTemplate={(item) => <span className={`Appkit4-icon icon-${item.iconName}`}></span>}
                >
                    <Avatar role="button" disabled={false} label={initials} />
                </DropdownButton>)}>
        </Header>
    )
}

export default LoggedInHeader;