import React, { useEffect, useState } from 'react';
import LoginPage from '../../pages/login/LoginPage';
import { KeycloakService } from '../../services/KeycloakService';
import { Provider } from 'react-redux';
import { store } from '../../store';
import { client } from '../../api/services.gen';
import LandingPage from '../../pages/landing/LandingPage';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NeedsAuth from '../components/NeedsAuth';
import { CreateNewProjectPage } from '../../pages/project';
import { Loading } from '@appkit4/react-components';

const App: React.FC = () => {
  const [keycloakInitialized, setKeycloakInitialized] = useState<boolean>(false);

  client.setConfig({
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    parseAs: 'json'
  });

  useEffect(() => {
    KeycloakService.initializeKeycloak(
      () => {
        setKeycloakInitialized(true);
        setInterval(async () => {
          await KeycloakService.keycloak.updateToken(30);
        }, 60000);
      },
      () => {
        setKeycloakInitialized(true);
      }
    );
  }, []);

  return (
    <Provider store={store}>
      {keycloakInitialized ? (
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/dashboard"
              element={
                <NeedsAuth>
                  <LandingPage />
                </NeedsAuth>
              }
            />
            <Route path="/createProject"
              element={
                <NeedsAuth>
                  <CreateNewProjectPage />
                </NeedsAuth>
              }
            /><Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      ) : (
        <Loading loadingType='circular' indeterminate={true} compact={false}></Loading>
      )}
    </Provider>
  );
};

export default App;