import { KeycloakOnLoad } from "keycloak-js";
import keycloak from "./Keycloak";

type KeycloakInitOptions = {
    onLoad?: KeycloakOnLoad;
    silentCheckSsoRedirectUri?: string;
    checkLoginIframe?: boolean;
    enableLogging?: boolean;
};

const initOptions: KeycloakInitOptions = {
    onLoad: 'check-sso',
    checkLoginIframe: false,
};

const initializeKeycloak = (onAuthenticatedCallback: () => void, onNotAuthenticatedCallback: () => void) => {
    keycloak.init(initOptions).then((authenticated) => {
        if (authenticated) {
            localStorage.setItem('token', keycloak.token || '');
            localStorage.setItem('refreshToken', keycloak.refreshToken || '');
            onAuthenticatedCallback();
        } else {
            onNotAuthenticatedCallback();
        }
    }).catch(() => {
        onNotAuthenticatedCallback();
    });
};

const getToken = () => {
    return localStorage.getItem('token') || '';
};

const refreshToken = async () => {
    try {
        await keycloak.updateToken(30);
        localStorage.setItem('token', keycloak.token || '');
    } catch (error) {
        console.error('Token refresh failed', error);
        keycloak.logout();
    }
};

const isAuthorized = () => {
    return keycloak.token && !keycloak.isTokenExpired();
};

const isAuthorizedRole = (role: string) => {
    return keycloak.tokenParsed?.realm_access?.roles.includes(role);
};

const getRoles = () => {
    return keycloak?.tokenParsed?.realm_access?.roles || [];
}

const getEmail = () => {
    return keycloak?.tokenParsed?.email;
}

const getOrganizationName = () => {
    const domainRegex = /^[^\s@]+@([^\s@]+\.[^\s@]+)$/;
    const match = keycloak?.tokenParsed?.email.match(domainRegex);

    if (match) {
        const domain = match[1];
        const domainParts = domain.split('.');
        return domainParts[0];
    }
    return null
}

const getInitials = () => {
    const fullName = keycloak?.tokenParsed?.name || '';
    const nameParts = fullName.split(' ');
    if (nameParts.length < 2) {
        return fullName.charAt(0).toUpperCase();
    }
    return nameParts[0].charAt(0).toUpperCase() + nameParts[1].charAt(0).toUpperCase();
}

const logout = () => {
    keycloak.logout({ redirectUri: `${window.location.origin}` });
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
};

export const KeycloakService = {
    keycloak,
    initializeKeycloak,
    getToken,
    refreshToken,
    isAuthorized,
    isAuthorizedRole,
    getEmail,
    getRoles,
    getInitials,
    getOrganizationName,
    logout
};
