import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

type EditProjectFormValues = {
  name: string;
  type: string;
  startDate: Date;
  endDate: Date;
  description: string;
};

type EditProjectFormProviderProps = {
  onSubmit: SubmitHandler<EditProjectFormValues>;
  children: React.ReactNode;
};

const EditProjectFormProvider: React.FC<EditProjectFormProviderProps> = ({ onSubmit, children }) => {
  const methods = useForm<EditProjectFormValues>();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default EditProjectFormProvider;
