import { Panel } from '@appkit4/react-components';
import { Upload } from '@appkit4/react-components/upload';
const CSVDetails: React.FC = () => {
    const onChange = (file: File, fileList: FileList): void => {
        console.log('onChange', file, fileList)
    }
    const uploadFiles = (fileList: any) => {
        console.log(fileList)
    }
    return (
        <div className="pt-4">
            <Panel bordered={false} title="File to import:" >
                <p>File may be compressed (gzip, bzip2, zip) or uncompressed.</p>
                <p>A compressed file's name must end in [format]-[compression] Example: .sql.zip</p>
                <p>(Max: 00MiB)</p>
                <Upload
                    onChange={onChange}
                    multiple={false}
                    autoUpload={true}
                    onUpload={uploadFiles}
                    acceptFileType=".JPG,.PNG,.PDF"
                    maxFileSize={10 * 1024 * 1024}
                    config={{
                        trigger: false,
                        type: 'inline',
                        size: true
                    }}
                >
                </Upload>
            </Panel>
        </div>
    )
}

export default CSVDetails;