import React, { useState } from 'react';
import { Badge, Button, Panel } from '@appkit4/react-components';
import backGroundImage from "../../assets/images/genericEmptyState.svg";
import { Tabs, Tab } from '@appkit4/react-components/tabs';
import { useSelector } from 'react-redux';
import { selectAllProjectsList } from '../../store/project/projectSelectors';
import { Loading } from '@appkit4/react-components/loading';
import { ProjectDTO } from '../../api';

const Landing: React.FC<{ onSubmit: () => void, loading: boolean, goToViewProject: (project: ProjectDTO) => void }> = ({ onSubmit, loading, goToViewProject }) => {
    const listOfProjects = useSelector(selectAllProjectsList);
    const archiveProjectData = listOfProjects?.filter((project)=> project?.projectStatus === "archive");
    const nonArchiveProjectData = listOfProjects?.filter((project)=> project?.projectStatus !== "archive");
    const [activeIndex, setactiveIndex] = useState(0);
    const onTabChange = (i: number, value: string) => {
        setactiveIndex(i);
    }
    const backgroundImageStyle = {
        backgroundImage: `url(${backGroundImage})`,
        backgroundRepeat: 'no-repeat',
        height: '22rem'
    };
    const viewProject = (project: ProjectDTO) => {
        goToViewProject(project);
    }
    return (
        <div>
            <h1 className="font-sans font-medium text-xl pl-8">Overview </h1>
            {loading ?
                <Loading loadingType='circular' indeterminate={true} compact={false}></Loading>
                :
                <>
                    {!(listOfProjects?.length > 0) ?
                        <div className="ml-8 mr-8 mt-2 mb-2 bg-white">
                            <div style={backgroundImageStyle} className="bg-center"></div>
                            <div className="flex flex-col text-center items-center">
                                <h1 className="font-medium text-xl p-2"> You didn't start a migration project yet </h1>
                                <div className="text-[#696969]"> Once you create one, you will see all your projects here<br /> and also be able to create new ones.</div>
                                <div className="p-4">
                                    <Button kind='primary' icon="icon-plus-outline" onClick={onSubmit}>Start a new project</Button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="ml-8 mr-8 mt-2 mb-2">
                            <h1 className="font-sans font-medium text-xl mb-4">Projects </h1>
                            <Tabs activeIndex={activeIndex} onTabChange={onTabChange}>
                                <Tab label='Current projects' value="currentProjects">
                                    <div className="flex flex-wrap">
                                        {nonArchiveProjectData?.map((project, index) =>
                                            <div className="w-1/3 mt-4 px-2" key={index}>
                                                <Panel title={project.name} className="min-h-56">
                                                    <Badge className="my-6" value={project.projectStatus} type={project.projectStatus === "ongoing" ? "success" : "warning"} />
                                                    <div className="flex justify-end mt-8">
                                                        <Button kind='primary' icon="icon-arrow-right-small-outline" onClick={() => viewProject(project)}>View Project</Button>
                                                    </div>
                                                </Panel>
                                            </div>
                                        )}
                                    </div>
                                </Tab>
                                <Tab label='Archive' value="Archive">
                                    <div className="flex flex-wrap">
                                        {archiveProjectData?.map((project, index) =>
                                            <div className="w-1/3 mt-4 px-2" key={index}>
                                                <Panel title={project.name} className="min-h-56">
                                                    <Badge className="my-6" value={project.projectStatus} type={"info"} />
                                                </Panel>
                                            </div>
                                        )}
                                    </div>
                                </Tab>
                            </Tabs>
                            <div className="flex justify-end p-4">
                                <Button kind='primary' icon="icon-plus-outline" onClick={onSubmit}>Start a new project</Button>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    );
};

export default Landing;
